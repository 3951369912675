<template>
    <b-container fluid>
        <div class="card card-primary card-outline">
            <div class="card-header">
                <div class="card-title">
                    Route bearbeiten
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="card card-success card-outline" style="width: 100%">
                        <div class="card-header">
                            <div class="card-title">
                                Allgemeines
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card-tools">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Bezeichnung*</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control form-control-sm" v-model="name">
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrzeug</label>
                                            <div class="col-md-9">
                                                <select class="form-control form-control-sm" v-model="car_id">
                                                    <option :value="0">-- Filter Fahrzeuge --</option>
                                                    <option v-for="car in cars" :key="car.id" :value="car.id">{{car.name}}</option>
                                                </select>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Auslieferungstag*</label>
                                            <div class="col-md-9">
                                                <input type="date" class="form-control form-control-sm" v-model="deliveryDay">
                                            </div>   
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrer</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control form-control-sm" v-model="drivers_name">
                                            </div>   
                                        </div>
                                    </div> -->
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrer</label>
                                            <div class="col-md-9">
                                                <select class="form-control form-control-sm" v-model="employee_id">
                                                    <option :value="null">-- Bitte wählen --</option>
                                                    <!-- <option v-for="employee in employees" :key="employee.id">{{employee.first_name}} {{employee.last_name}}</option> -->
                                                    <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                                                        <option v-for="employee in group.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                                                    </optgroup>
                                                </select>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer text-right">
                <button class="btn btn-primary btn-l" :disabled="name == '' || deliveryDay == '' || car_id == 0" @click="createRoute">
                    <i class="fas fa-save"></i> Speichern
                </button>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: 'RouteCreate',
    title: 'Route erstellen',
    data() {
        return {
            name: '',
            car_id: 0,
            deliveryDay: '',
            drivers_name: '',
            employee_id: null,
            cars: [],
            groups: [],
        };
    },
    methods: {
        loadCars()
        {
            this.axios
                .get('/cars')
                .then((response) => {
                    this.cars = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        createRoute() {
            this.axios
                .post("/routes", {
                    name: this.name,
                    car_id: this.car_id,
                    deliveryDay: this.deliveryDay,
                    drivers_name: this.drivers_name,
                    employee_id: this.employee_id
                })
                .then((response) => {
                    this.$swal({
                        icon: "success",
                        title: "Streckenplanung erstellt.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.$router.push({name: 'routes.details', params: {id: response.data.route_id}});
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        getEmployees()
        {
            this.axios
                .get("/employee/groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Mitarbeiter ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.loadCars();
        this.getEmployees();
    }
}
</script>